<template>
  <div>Group Index page</div>
</template>
<script setup lang="ts">
import { useNoticeStore } from '@/stores/notice.store';
import { definePageMeta } from '#imports';

definePageMeta({
  middleware: ['redirect-middleware']
});

const noticeStore = useNoticeStore();

const { fetchLatestNotice } = noticeStore;

fetchLatestNotice();
</script>
